import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../axios';
import EventImg from '../img/events/event-view.jpg';
import HeaderSecond from '../HeaderSecond';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShareEvent from './ShareEvent';
import NoEventImg from '../img/events/no-events.png';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'

const SearchEvent = (props) => {
    const navigate = useNavigate()
    // BELOW 3 STATES ARE FROM LOCATION
    const [EventName, setEventName] = useState(''); // FROM Header.js
    const [CategoryFilter, setCategoryFilter] = useState(''); //FROM CategorySection.js getting category_id
    const [QuicklyFilter, setQuicklyFilter] = useState(''); // FROM CategorySection.js gettings quick filter 

    const [EventsData, setEventsData] = useState([]);
    // SEARCHING FILTERS
    const [AllType, setAllType] = useState([]);
    const [EndDate, setEndDate] = useState('');
    const [StartDate, setStartDate] = useState('');
    const [City, setCity] = useState('');
    const [State, setState] = useState('');
    const [Country, setCountry] = useState('');
    const [AllCity, setAllCity] = useState([]);
    const [Index, setIndex] = useState(6);
    const [ApiCall, setApiCall] = useState(false);
    const [TotalEvent, setTotalEvent] = useState(0);
    const [AllDistance, setAllDistance] = useState([]);
    const [Distance, setDistance] = useState(0);
    const [DistanceApiCall, setDistanceApiCall] = useState(true);


    const [ShareEventFlag, setShareEventFlag] = useState(false);
    const [EventIdShareEvent, setEventIdShareEvent] = useState('');
    const [Val, setVal] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [ShowSuggestion, setShowSuggestion] = useState(false);

    const [CityApiCall, setCityApiCall] = useState(true);
    const DivRef = useRef(null);
    const [Login, setLogin] = useState(false);
    const [FilterDiv, setFilterDiv] = useState(false);

    const location = useLocation();
    // console.log("location : " + location);
    // console.log("filter from : " + location.state.filter_name);
    // console.log("filter name : " + location.state.filter);
    useEffect(() => {
        if (location.state) {
            if (location.state.filter_name !== "") {
                if (location.state.filter_name === "category") {
                    // console.log("cat");
                    setCategoryFilter(location.state.filter);
                    setApiCall(true);
                } else if (location.state.filter_name === "quick_filter") {
                    // console.log("quick");
                    setQuicklyFilter(location.state.filter);
                    setApiCall(true);
                } else if (location.state.filter_name === "event_name") {
                    // console.log("event : "+location.state.filter);
                    // return false;
                    setEventName(location.state.filter);
                    // console.log(EventName);
                    setApiCall(true);
                }
            }
            if (location.state.from_popular_events === 1) {
                setApiCall(false);
                // setEventsData(location.state.popular_events);
                // setTotalEvent(location.state.popular_events.length)

                const payload = {
                    city: localStorage.getItem("CityId"),
                    scity: localStorage.getItem("CityId"),
                    state: localStorage.getItem("StateId"),
                    country: localStorage.getItem("CountryId")
                }
                // console.log(payload);
                axios.post("get_data_location_wise", payload)
                    .then((response) => {
                        setEventsData(response.data.data.eventData);
                        setTotalEvent(response.data.data.eventData.length);
                    })
                    .catch((error) => {
                        console.log(error);
                    })

            }
        }
    }, [location.state])

    // console.log(EventName);

    useEffect(() => {
        if (ApiCall) {
            const payload = {
                // FROM CATEGORY EITHER CATEGORY ID OR QUICK FILTER
                event_name: EventName,
                filter: QuicklyFilter,
                category_id: CategoryFilter,
                start_date: StartDate,
                end_date: EndDate,
                distance: Distance,
                country: Country,
                state: State,
                city: City
            };
            // console.log(ApiCall, payload);
            axios.post("events", payload)
                .then((response) => {
                    // console.log(response.data.data.EventData);
                    setApiCall(false);
                    setEventsData(response.data.data.EventData);
                    setTotalEvent(response.data.data.EventData.length)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [ApiCall, EventName, QuicklyFilter, CategoryFilter, StartDate, Country, State, City, EndDate, Distance])

    const handleSubmit = (e, type) => {
        // console.log(type);
        if (type === 'quick_filter') setQuicklyFilter(e.target.value);
        // console.log(QuicklyFilter);
        e.preventDefault();
        setApiCall(true);
    }
    const HandleClear = (e) => {
        e.preventDefault();
        // if(StartDate !== "" || CategoryFilter !== ""  || Country !== "" || City !== "" ||  State !== "" ||  EndDate !== "" || QuicklyFilter !== "" || EventName !== "" || Distance !== "" || Val !== ""){
        setStartDate(''); setCategoryFilter(0); setCountry(''); setCity(''); setState('');
        setQuicklyFilter(''); setEventName(''); setEndDate(''); setDistance(0); setVal('');
        setAllType([]); setAllDistance([]);
        setApiCall(true);
        // }else{
        //     toast.error("Please select at least one option!")
        // }
    }
    // GET ALL EVENT Types(CATEGORY)
    useEffect(() => {
        if (AllType.length === 0) {
            axios.get('types')
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        setAllType(response.data.data.AllEventTypes);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [AllType])

    // GET ALL DISTANCES OF EVENTS
    useEffect(() => {
        if (AllDistance.length > 0 && DistanceApiCall) {
            axios.get('get_distance')
                .then((response) => {
                    // console.log(response.data.data.AllDistances);
                    if (response.data.status === 200) {
                        setAllDistance(response.data.data.AllDistances);
                        setDistanceApiCall(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [AllDistance, DistanceApiCall])

    // GET ALL CITIES
    const CountryId = localStorage.getItem("CountryId") ? localStorage.getItem("CountryId") : 101;
    useEffect(() => {
        if (CityApiCall) {
            axios.post('city', {
                country_id: CountryId,
                search_flag: 1
            })
                .then((response) => {
                    // console.log(response.data.data.AllEventTypes);
                    if (response.data.status === 200) {
                        setAllCity(response.data.data.AllCities);
                        setCityApiCall(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [CityApiCall, CountryId])
    // console.log(AllCity);

    const HandleBack = (e) => {
        const CountryCode = localStorage.getItem("CountryCode");
        const CityName = localStorage.getItem("City") ? localStorage.getItem("City") : localStorage.getItem("Country");
        navigate("/" + CountryCode + "/" + CityName);
    }

    const handleWishList = (eventId, is_follow) => {
        // alert("here");
        // is_follow == 0 then need to follow means add entry in table
        // is_follow == 1 then need to unfollow means delete the entry form table
        const RacesToken = localStorage.getItem("RacesToken") ? localStorage.getItem("RacesToken") : "";
        if (RacesToken) {
            const payload = {
                "event_id": eventId,
                "is_follow": is_follow
            }
            axios.post("/follow", payload)
                .then((response) => {
                    // console.log(response.data.message);
                    if (response.status) {
                        toast.success(response.data.message);
                        setApiCall(true)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('Error occured while adding to wishlist')
                    }
                })
        } else {
            // const CountryCode = localStorage.getItem("CountryCode");
            // const CityName = localStorage.getItem("City") ? localStorage.getItem("City") : localStorage.getItem("Country");
            // navigate("/" + CountryCode + "/" + CityName, { state: { "login_popup": true } });
            setLogin(true);
        }
    }
    const handleShareEvent = (eventId, eventName) => {
        setShareEventFlag(true);
        setEventName(eventName);
        setEventIdShareEvent(eventId);
    }
    const handleInputChange = (e) => {
        const value = e.target.value;
        // console.log(AllCity);
        setVal(value);
        if (value === "") {
            setCity('');
            handleSubmit(e)
        }
        // Filter suggestions based on input value
        const filteredSuggestions = AllCity.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        const SliceArr = filteredSuggestions.slice(0, 5)
        // console.log(SliceArr);
        setSuggestions(SliceArr);
        setShowSuggestion(true);
    };
    const handleSuggestionClick = (e, id, name) => {
        setCity(id);
        setVal(name)
        setSuggestions([]);
        handleSubmit(e)
    };
    const handleClose = (e) => {
        if (DivRef.current && !DivRef.current.contains(e.target)) {
            setShowSuggestion(false);
        }
    }

    const [pageRefreshed, setPageRefreshed] = useState(false);

    useEffect(() => {
        // Check if the current location is different from the previous one
        const isPageRefreshed = window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD;
        // If the page is refreshed, update the state
        if (isPageRefreshed) {
            setPageRefreshed(true);
        }
    }, []);

    useEffect(() => {
        if (pageRefreshed) {
            navigate('/events', { state: [] });
            setApiCall(true);
        }
    }, [navigate, pageRefreshed]);

    const handleEventDetails = (e, EventId) => {
        navigate('/event_details', { state: { event_id: EventId } })
    }

    return (
        <>
            <ToastContainer theme="colored" />
            <HeaderSecond Login={Login} setLogin={setLogin} />
            {ShareEventFlag ? <ShareEvent setShareEventFlag={setShareEventFlag} EventName={EventName} EventIdShareEvent={EventIdShareEvent} /> : null}
            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        Explore Events
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => HandleBack(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">Search Event</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <div className="container-fluid" onClick={(e) => { handleClose(e) }}>
                        <div className="row" >

                            {/* START FILTER DIV */}
                            <div className="col-xl-3 col-lg-4">
                                <div className={FilterDiv ? "event-filters widget__area" : "widget__area d-md-2-none "}>
                                    <div className="single__widget widget__bg">
                                        <div className="d-flex justify-content-between">
                                            <h2 className="widget__title position__relative h3 m-0">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M200-160v-280h-80v-80h240v80h-80v280h-80Zm0-440v-200h80v200h-80Zm160 0v-80h80v-120h80v120h80v80H360Zm80 440v-360h80v360h-80Zm240 0v-120h-80v-80h240v80h-80v120h-80Zm0-280v-360h80v360h-80Z" /></svg> Filters</h2>

                                            <svg onClick={(e) => HandleBack(e)} className="back-to-home" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" /></svg>

                                            <svg onClick={(e) => setFilterDiv(false)} className="event-filters-close-btn" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                                        </div>

                                        {/* <form onSubmit={(e) => handleSubmit(e,'')}> */}
                                        <div className="checkout__input--list">
                                            <div className="checkout__input--list my-5">
                                                <label className="checkout__input--label mb-2">
                                                    By Event Name
                                                </label>
                                                <input
                                                    className="checkout__input--field border-radius-5"
                                                    placeholder="Search by event name"
                                                    type="text"
                                                    value={EventName}
                                                    onChange={(e) => { setEventName(e.target.value); handleSubmit(e, '') }}
                                                />
                                            </div>

                                            <div className="checkout__input--list my-5">
                                                <label className="checkout__input--label mb-3">
                                                    City
                                                </label>
                                                <input className="predictive__search--input" placeholder="Select City" type="text" onChange={(e) => { handleInputChange(e); }} value={Val} />
                                                <div className="autocomplete" style={{cursor: 'pointer'}}>
                                                    {ShowSuggestion ?
                                                        <ul ref={DivRef} style={{ position: 'relative', width: '100%'}}>
                                                            {
                                                                (suggestions.length > 0) ?
                                                                    suggestions.map((item) => {
                                                                        // console.log(item);
                                                                        return (
                                                                            <li key={item.id}
                                                                                onClick={(e) => handleSuggestionClick(e, item.id, item.name)}
                                                                            >{item.name}</li>
                                                                        )
                                                                    }) : null
                                                            }
                                                        </ul>
                                                        : null
                                                    }
                                                </div>
                                            </div>


                                            <div className="checkout__input--list my-5">
                                                <label className="checkout__input--label mb-3">
                                                    By Distance
                                                </label>
                                                <div className="checkout__input--select select">
                                                    <select
                                                        className="checkout__input--select__field border-radius-5"
                                                        onChange={(e) => {
                                                            setCategoryFilter(e.target.value);
                                                            handleSubmit(e, '');
                                                        }}
                                                        value={CategoryFilter} // Set the value prop here
                                                    >
                                                        <option value={0}>Select Type</option>
                                                        {AllType && AllType.length > 0 ? (
                                                            AllType.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))
                                                        ) : null}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="checkout__input--list my-5">
                                                <label className="checkout__input--label mb-3">
                                                    Registration Start Date
                                                </label>
                                                <input
                                                    className="checkout__input--field border-radius-5 position-relative"
                                                    placeholder="" type="date"
                                                    value={StartDate}
                                                    onChange={(e) => { setStartDate(e.target.value); handleSubmit(e, '') }}
                                                />
                                            </div>

                                            <div className="checkout__input--list my-5">
                                                <label className="checkout__input--label mb-3">
                                                    Registration Closing on
                                                </label>
                                                <input
                                                    className="checkout__input--field border-radius-5 position-relative"
                                                    placeholder="" type="date"
                                                    value={EndDate}
                                                    onChange={(e) => { setEndDate(e.target.value); handleSubmit(e, '') }}
                                                />
                                            </div>

                                            {/* <div className="checkout__input--list my-5">
                                                <label className="checkout__input--label mb-3">
                                                    Distance
                                                </label>
                                                <div className="checkout__input--select select">
                                                    <select className="checkout__input--select__field border-radius-5"
                                                        onChange={(e) => { setDistance(e.target.value); handleSubmit(e, '') }}
                                                    >
                                                        <option value={0}>Select Distance</option>
                                                        {
                                                            (AllDistance && AllDistance.length > 0 ?
                                                                AllDistance.map((item) => {
                                                                    return (
                                                                        <option value={item.distance} selected={CategoryFilter === item.distance}>{item.distance}</option>
                                                                    );
                                                                }) : null
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div> */}


                                            <br />
                                            <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4 hand_cursor">
                                                <div className="continue__shipping--btn secondary__btn border-radius-5"
                                                    onClick={(e) => HandleClear(e)}>
                                                    Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* END FILTER DIV */}

                            <div className="col-xl-9 col-lg-8">
                                <div className="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30">
                                    <button className="widget__filter--btn d-none d-md-2-flex align-items-center" onClick={(e) => setFilterDiv(true)}>
                                        <svg
                                            className="widget__filter--btn__icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={28}
                                                d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80"
                                            />
                                            <circle
                                                cx={336}
                                                cy={128}
                                                r={28}
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={28}
                                            />
                                            <circle
                                                cx={176}
                                                cy={256}
                                                r={28}
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={28}
                                            />
                                            <circle
                                                cx={336}
                                                cy={384}
                                                r={28}
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={28}
                                            />
                                        </svg>
                                        <span className="widget__filter--btn__text">Filter</span>
                                    </button>

                                    <div className="product__view--mode__list product__short--by align-items-center d-none d-lg-flex">
                                        <p className="product__showing--count m-0">Showing {TotalEvent !== 0 ? 1 : 0}–{Index < TotalEvent ? Index : TotalEvent} of {TotalEvent} results</p>
                                    </div>

                                    {/* <div className="product__view--mode d-flex align-items-center">

                                        <div className="product__view--mode__list product__short--by align-items-center d-none d-lg-flex">
                                            < div className="select shop__header--select">
                                                <select className="product__view--select" onChange={(e) => handleSubmit(e, 'quick_filter')}>
                                                    <option value={1}>Sort by latest</option>
                                                    <option value="weekend" selected={QuicklyFilter === "weekend"}>Weekend</option>
                                                    <option value="week" selected={QuicklyFilter === "week"}>Week</option>
                                                    <option value="month" selected={QuicklyFilter === "month"}>Month</option>
                                                    <option value="tomorrow" selected={QuicklyFilter === "tomorrow"}>Tomorrow</option>
                                                    <option value="today" selected={QuicklyFilter === "today"}>Today</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div> */}

                                    <div className="product__view--mode d-flex align-items-center">
                                        <div className="product__view--mode__list product__short--by align-items-center d-none d-lg-flex">
                                            <div className="select shop__header--select">
                                                <select
                                                    className="product__view--select"
                                                    onChange={(e) => handleSubmit(e, 'quick_filter')}
                                                    value={QuicklyFilter}
                                                >
                                                    <option value={1}>Sort by latest</option>
                                                    <option value="tomorrow">Tomorrow</option>
                                                    <option value="week">This Week</option>
                                                    <option value="month">This Month</option>
                                                    {/* <option value="weekend">Weekend</option> */}
                                                    <option value="quarter">This Quarter</option>
                                                    <option value="today">Today</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="shop__product--wrapper">
                                    {/* EVENTS LISTING TABLE START HERE */}
                                    <div className="tab_content">
                                        <div className="tab_pane active show">
                                            <div className="product__section--inner product__grid--inner">
                                                {/* {console.log(EventsData)} */}
                                                {
                                                    EventsData && EventsData.length > 0 ? (
                                                        <div className="row">
                                                            {EventsData.map((event, index) => {
                                                                // console.log(Index,index);
                                                                return (
                                                                    ((Index) > parseInt(index)) ?
                                                                        // <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 my-4">
                                                                        <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 py-4" style={{ position: "relative" }}>
                                                                            <div key={event.id} className="product__items"
                                                                                // onClick={(e) => { HandleViewEvent(event.id) }}
                                                                                onClick={(e) => handleEventDetails(e, event.event_id ? event.event_id : event.id)}
                                                                            >
                                                                                <div className="product__items--thumbnail" >
                                                                                    <div className="product__items--link">
                                                                                        <img
                                                                                            className="product__items--img product__primary--img"
                                                                                            src={event.banner_image || EventImg}
                                                                                            alt="Event View"
                                                                                            style={{ height: '230px', objectFit: 'fill' }}
                                                                                        />
                                                                                    </div>
                                                                                    {event.TicketDetails && event.TicketDetails.length > 0 && (
                                                                                        event.TicketDetails.some(ticket => (parseInt(ticket.show_early_bird) === 1 && parseInt(ticket.ticket_status) !== 2)) ? (
                                                                                            <div className="product__badge style1 special-badge gap-1">
                                                                                                Early Bird
                                                                                            </div>
                                                                                        ) : null
                                                                                    )}
                                                                                    <ul className="product__items--action d-flex justify-content-center">

                                                                                        {/* Wishlist */}
                                                                                        <li className="product__items--action__list">
                                                                                            <div className={event.is_follow ? "hand_cursor product__items--action__btn wishlisted" : "hand_cursor product__items--action__btn"} onClick={(e) => {
                                                                                                e.stopPropagation(); // Stop event propagation
                                                                                                handleWishList(event.id, event.is_follow);
                                                                                            }}>
                                                                                                <svg
                                                                                                    className="product__items--action__btn--svg"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="17.51"
                                                                                                    height="15.443"
                                                                                                    viewBox="0 0 24.526 21.82"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M12.263,21.82a1.438,1.438,0,0,1-.948-.356c-.991-.866-1.946-1.681-2.789-2.4l0,0a51.865,51.865,0,0,1-6.089-5.715A9.129,9.129,0,0,1,0,7.371,7.666,7.666,0,0,1,1.946,2.135,6.6,6.6,0,0,1,6.852,0a6.169,6.169,0,0,1,3.854,1.33,7.884,7.884,0,0,1,1.558,1.627A7.885,7.885,0,0,1,13.821,1.33,6.169,6.169,0,0,1,17.675,0,6.6,6.6,0,0,1,22.58,2.135a7.665,7.665,0,0,1,1.945,5.235,9.128,9.128,0,0,1-2.432,5.975,51.86,51.86,0,0,1-6.089,5.715c-.844.719-1.8,1.535-2.794,2.4a1.439,1.439,0,0,1-.948.356ZM6.852,1.437A5.174,5.174,0,0,0,3,3.109,6.236,6.236,0,0,0,1.437,7.371a7.681,7.681,0,0,0,2.1,5.059,51.039,51.039,0,0,0,5.915,5.539l0,0c.846.721,1.8,1.538,2.8,2.411,1-.874,1.965-1.693,2.812-2.415a51.052,51.052,0,0,0,5.914-5.538,7.682,7.682,0,0,0,2.1-5.059,6.236,6.236,0,0,0-1.565-4.262,5.174,5.174,0,0,0-3.85-1.672A4.765,4.765,0,0,0,14.7,2.467a6.971,6.971,0,0,0-1.658,1.918.907.907,0,0,1-1.558,0A6.965,6.965,0,0,0,9.826,2.467a4.765,4.765,0,0,0-2.975-1.03Zm0,0"
                                                                                                        transform="translate(0 0)"
                                                                                                        fill="currentColor"
                                                                                                    />
                                                                                                </svg>
                                                                                                <span className="visually-hidden">Favourites</span>
                                                                                            </div>
                                                                                        </li>

                                                                                        <li className="product__items--action__list">
                                                                                            <div className="product__items--action__btn hand_cursor" onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                handleShareEvent(event.id, event.name)
                                                                                            }}>
                                                                                                <svg
                                                                                                    className="product__items--action__btn--svg"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="16.47"
                                                                                                    height="13.088"
                                                                                                    viewBox="0 0 15.47 11.088"
                                                                                                >
                                                                                                    <g transform="translate(0 -72.508)">
                                                                                                        <path
                                                                                                            data-name="Path 114"
                                                                                                            d="M15.359,80.9l-2.011-2.011a.525.525,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49l1.106,1.106H10.576L8.3,78.052l2.273-2.274h1.618l-1.106,1.106a.287.287,0,0,0,.2.49h1.683a.531.531,0,0,0,.374-.155l2.011-2.011a.38.38,0,0,0,0-.535l-.859-.859a.227.227,0,1,0-.32.321l.806.806L13.027,76.9a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542h-2.04a.227.227,0,0,0-.16.066l-2.34,2.34-.544-.544,2.519-2.519a.382.382,0,0,1,.272-.112h2.293a.317.317,0,0,0,.225-.542l-1.054-1.054h1.282a.076.076,0,0,1,.053.022l.4.4a.227.227,0,1,0,.32-.321l-.4-.4a.531.531,0,0,0-.374-.155H11.291a.287.287,0,0,0-.2.49L12.194,74.1H10.229a.832.832,0,0,0-.592.245L7.118,76.867,4.6,74.349a.832.832,0,0,0-.592-.245H.378A.378.378,0,0,0,0,74.481v.92a.378.378,0,0,0,.378.378H3.66l2.273,2.274L3.66,80.326H.378A.378.378,0,0,0,0,80.7v.92A.378.378,0,0,0,.378,82H4.007a.835.835,0,0,0,.592-.245l2.519-2.519.8.8a.227.227,0,1,0,.32-.32L3.914,75.392a.227.227,0,0,0-.16-.066H.453v-.769H4.007a.382.382,0,0,1,.272.113l6.043,6.043a.227.227,0,0,0,.16.066h2.04a.317.317,0,0,0,.224-.542l-1.054-1.054h1.282a.075.075,0,0,1,.053.022l1.958,1.958-1.958,1.958a.075.075,0,0,1-.053.022H11.692l1.054-1.054a.317.317,0,0,0-.224-.542H10.229a.383.383,0,0,1-.272-.113l-.968-.968a.227.227,0,0,0-.32.32l.968.968a.833.833,0,0,0,.592.245h1.965l-1.105,1.105a.287.287,0,0,0,.2.49h1.683a.525.525,0,0,0,.374-.155l2.011-2.011A.379.379,0,0,0,15.359,80.9Zm-11.08.539a.389.389,0,0,1-.272.113H.453v-.769h3.3a.226.226,0,0,0,.16-.066l2.34-2.34.543.544Z"
                                                                                                            transform="translate(0 0)"
                                                                                                            fill="currentColor"
                                                                                                        />
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <span className="visually-hidden">Compare</span>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                                <div className="product__items--content cursor-pointer" style={{ height: "200px", overflow: "hidden" }}>

                                                                                    <div className="row">
                                                                                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 d-flex flex-column align-items-center justify-content-center" style={{ borderRight: "1px solid #f52222" }}>
                                                                                            <h4 className="h4">{event.start_event_month ? event.start_event_month : null}</h4>
                                                                                            <h4 className="h4">{event.start_event_date ? event.start_event_date : null}</h4>
                                                                                        </div>
                                                                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10" >

                                                                                            <h4 className="h4 d-flex" style={{ height: "55px", overflow: "hidden" }}>  {event.display_name}</h4>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='my-3 d-flex gap-3'>

                                                                                        <small className="m-0 card-event-city">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                height={17}
                                                                                                viewBox="0 -960 960 960"
                                                                                                width={17}
                                                                                            >
                                                                                                <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                                                                            </svg> {event.city_name ? event.city_name : null}</small>
                                                                                    </div>

                                                                                    <div className="row d-flex align-items-center card-rgn-bt-dot">
                                                                                        <div className="col-sm-12 mt-4 mb-0">
                                                                                            <small className='mb-2 card-rgn-txt'>Register By
                                                                                                : {" "}  <span className='rgn-date'>{event.registration_end_date ? event.registration_end_date : null}</span></small>
                                                                                        </div>
                            {event.registration_close_flag === 0 ?
                            <div className="col-sm-6 my-3">
                                <p style={{ whiteSpace: "nowrap" }}>
                                    {event.TicketDetails && event.TicketDetails.length > 0 && (
                                        event.TicketDetails.some(ticket => parseInt(ticket.ticket_status) === 2) ? (
                                            <span className="current__price m-0">Free</span>
                                        ) : (
                                            event.TicketDetails.map((ticket, index) => (
                                                parseInt(ticket.ticket_status) === 1 && (
                                                    <span key={index}>
                                                        {index > 0 && " - "}
                                                        {ticket.show_early_bird === 1 ? (
                                                            <span className="text-left">
                                                                <span className="ticket-prices">
                                                                    <span className="original-price"> ₹{ticket.ticket_price}</span>&nbsp;
                                                                    <span className="current__price m-0">₹{ticket.discount_ticket_price}</span>
                                                                </span>
                                                            </span>
                                                        ) : (
                                                            <span className="current__price m-0">₹{ticket.ticket_price}</span>
                                                        )}
                                                    </span>
                                                )
                                            ))
                                        )
                                    )}
                                </p>
                            </div>
                            :
                                <div className="col-sm-8 my-3">
                                    <p className="m-0 p-1 text-danger"><svg style={{fill:"#dc3545"}} xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#dc3545"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q54 0 104-17.5t92-50.5L228-676q-33 42-50.5 92T160-480q0 134 93 227t227 93Zm252-124q33-42 50.5-92T800-480q0-134-93-227t-227-93q-54 0-104 17.5T284-732l448 448Z"/></svg> Registration Closed</p>
                                </div>
                            }
                            
                            {event.registration_close_flag === 0 ?
                                <div className="col-sm-6 my-3 text-right">
                                    <p className="blog__content--btn primary__btn">Register</p>
                                </div>
                            :
                                <div className="col-sm-4 my-3 text-right">
                                    <p className="blog__content--btn primary__btn">View</p>
                                </div>
                            }
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                );
                                                            })}
                                                        </div>
                                                    ) :
                                                        <div className="product__section--inner py-5 text-center">
                                                            <img src={NoEventImg} alt="" style={{ width: 250 }} />
                                                            <h4 className="pt-4">No events found</h4>
                                                            <p>
                                                                Sorry, We couldn't find any events that match your search, please change the filters!
                                                            </p>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* END EVENTS LISTING TABLE START HERE */}

                                    {Index < EventsData.length ?
                                        <div className="pagination__area bg__gray--color">
                                            <nav className="pagination justify-content-center">
                                                <div
                                                    className="continue__shipping--btn secondary__btn border-radius-5"
                                                    onClick={(e) => setIndex(Index + 3)}
                                                >
                                                    Load more
                                                </div>
                                            </nav>
                                        </div>
                                        : null
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </>

    )
}

export default SearchEvent