import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useParams, useNavigate } from 'react-router-dom';
import LoaderImg from '../img/logo/running.gif';
import CouponCsvUploadDetails from "../Create_event_details/CouponCsvUploadDetails";
import noCoupons from "../img/other/no-coupon.png";
import axios from '../axios';
import { toast } from 'react-toastify';

const AddDiscountCoupons = (props) => {
   
  const navigate = useNavigate();
  const [Loader, setLoader] = useState(false);
  const ref = useRef();
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);

  const [DiscounType, setDiscounType] = useState('1');
  const [OneTimeUse, setOneTimeUse] = useState('active');  
  const [MultipleTimeUse, setMultipleTimeUse] = useState(''); 

  const [DiscounName, setDiscounName] = useState('');
  const [DiscountAmount, setDiscountAmount] = useState('');
  const [DiscountPercentage, setDiscountPercentage] = useState('');
  const [CodeType, setCodeType] = useState('1');
  const [NoOfDiscounts, setNoOfDiscounts] = useState('');
  const [DiscountCode, setDiscountCode] = useState('');
  const [PrefixCode, setPrefixCode] = useState('');
  const [DiscountAmtPerType, setDiscountAmtPerType] = useState('1');

  const [DiscountFromDate, setDiscountFromDate] = useState('');
  const [DiscountToDate, setDiscountToDate] = useState('');
  const [DiscountFromTime, setDiscountFromTime] = useState('');
  const [DiscountToTime, setDiscountToTime] = useState('');
  const [HaveListCodes, setHaveListCodes] = useState(false);
  const [TicketsData, setTicketsData] = useState([]);
  const imageSizeLimit = localStorage.getItem('MAX_UPLOAD_FILE_SIZE');
  const [UploadCsvfile, setUploadCsvfile] = useState('');
  
  const [ApplayTickets, setApplayTickets] = useState(1);
  const [AllTicktes, setAllTicktes] = useState('active');  
  const [SelectedTicktes, setSelectedTicktes] = useState('');
  const [UserEmailAddress, setUserEmailAddress] = useState('');
  
  const [DiscounNameError, setDiscounNameError] = useState('');
  const [DiscountPercentageError, setDiscountPercentageError] = useState('');
  const [DiscountAmountError, setDiscountAmountError] = useState('');
  const [NoOfDiscountsError, setNoOfDiscountsError] = useState('');
  const [DiscountCodeError, setDiscountCodeError] = useState('');
  const [UserEmailAddressError, setUserEmailAddressError] = useState('');
  
  const [DiscountFromDateError, setDiscountFromDateError] = useState('');
  const [DiscountToDateError, setDiscountToDateError] = useState('');
  const [DiscountFromTimeError, setDiscountFromTimeError] = useState('');
  const [DiscountToTimeError, setDiscountToTimeError] = useState('');
  const [UploadCsvfileError, setUploadCsvfileError] = useState('');
  const [SelectedTicketError, setSelectedTicketError] = useState('');
  const [UploadedFileName, setUploadedFileName] = useState('');
  const [filedivFlag, setfiledivFlag] = useState(0);
  const [ButtonDisable, setButtonDisable] = useState(false);

  const [EventCommId, setEventCommId] = useState(''); 
  const [CsvUpoldedPopup, setCsvUpoldedPopup] = useState(false);
  const [Description, setDescription] = useState('');

  const [ShowPublic, setShowPublic] = useState(0);
  const [YesStatus, setYesStatus] = useState('');  
  const [NoStatus, setNoStatus] = useState('active'); 
  
  // console.log(ShowPublic);

  useEffect(() => {
    if(props.TicketsDetails){
      setTicketsData(props.TicketsDetails ? props.TicketsDetails : '');
    }else{
      setButtonDisable(true);
    }
  }, [props.TicketsDetails])


  // console.log(ButtonDisable);
  // useEffect(() => {
    
  useEffect(() => {
    
    if (props.CommId && props.CommId !== 0) {
      props.setLoader(true);
      axios.post('edit_event_comm_faq',
        {
          'event_id': localStorage.getItem("EventId"),
          'event_comm_id': props.CommId,
          'event_edit_flag': 'coupon_edit'
        }
      )
        .then((response) => {
         //console.log(response.data);
          props.setLoader(false);
          if (response.data.data.coupon_edit_details) {
            let comm_details = response.data.data.coupon_edit_details[0];
            //console.log(comm_details);
              setEventCommId(comm_details.id !== '' ? comm_details.id : '')
              setDiscounType(comm_details.discount_type !== '' ? comm_details.discount_type : '');
              setDiscounName(comm_details.discount_name !== '' ? comm_details.discount_name : '');
              setDiscountAmtPerType(comm_details.discount_amt_per_type !== '' ? comm_details.discount_amt_per_type : '');
              setDiscountPercentage(comm_details.discount_percentage !== '' ? comm_details.discount_percentage : '');
              setDiscountAmount(comm_details.discount_amount !== '' ? comm_details.discount_amount : '');
              setCodeType(comm_details.code_type !== '' ? comm_details.code_type : '');
              setNoOfDiscounts(comm_details.no_of_discount !== '' ? comm_details.no_of_discount : '');
              setDiscountCode(comm_details.discount_code !== '' ? comm_details.discount_code : '');
              setPrefixCode(comm_details.prefix_code !== '' ? comm_details.prefix_code : '');
            
              setDiscountFromDate(comm_details.discount_from_date !== '' ? comm_details.discount_from_date : '');
              setDiscountToDate(comm_details.discount_to_date !== '' ? comm_details.discount_to_date : '');
              setDiscountFromTime(comm_details.discount_from_time !== '' ? comm_details.discount_from_time : '');
              setDiscountToTime(comm_details.discount_to_time !== '' ? comm_details.discount_to_time : '');
              setTicketsData(comm_details.edit_ticket_details !== '' ? comm_details.edit_ticket_details : '');
              setApplayTickets(comm_details.apply_ticket !== '' ? comm_details.apply_ticket : '');
              setDescription(comm_details.description !== '' ? comm_details.description : '');
              setShowPublic(comm_details.show_public !== '' ? comm_details.show_public : '');
              setUserEmailAddress(comm_details.user_email_address !== '' ? comm_details.user_email_address : '');
              
              if(comm_details.apply_ticket === 1){
                setAllTicktes('active'); setSelectedTicktes('');
              }else{
                setAllTicktes(''); setSelectedTicktes('active');
              }

              if(comm_details.discount_type === 1){
                setOneTimeUse('active'); setMultipleTimeUse('');
              }else{
                setOneTimeUse(''); setMultipleTimeUse('active');
              }

              if(comm_details.show_public === 1){
                setYesStatus('active'); setNoStatus('');
              }else{
                setYesStatus(''); setNoStatus('active');
              }

          }

        })
        .catch((error) => {
          props.setLoader(false);
          console.log(error);
        })
    }
    // props.setTicketDivShowFlag(true);
   }, [props.CommId])

  // console.log(CodeType);

  const uplodCSVFile = (e) => {

    let file = e.target.files[0];
    setUploadCsvfile(file);
    setUploadedFileName(file.name);
    // if (file.size > imageSizeLimit) {
    //   setUploadCsvfile(file);
    // } else {
    //   toast.error('File size exceeds 2MB limit.');
    //   // ref.current.value = "";
    // }
    // console.log(file.name);
  }
  
  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setUserEmailAddress(value);
    // Regular expression for validating email addresses
    const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}(?:, ?[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})*$/;
    setIsValid(emailRegex.test(value));
  };

  //console.log(isValid);

  const handleValidation = () => {
   
    var flag = true;
    if (DiscounName === "") {
      setDiscounNameError("Please enter discount name");
      flag = false
    } else {
      setDiscounNameError("");
    }

    // if (DiscountPercentage === "" && DiscountAmount === "") {
    //   setDiscountPercentageError("Please enter discount percentage");
    //   setDiscountAmountError("Please enter discount amount");
    //   flag = false
    // } else {
    //   setDiscountPercentageError(""); setDiscountAmountError("");
    // }

   
    if(DiscountAmtPerType === '1'){
      if (DiscountPercentage === "") {
        setDiscountPercentageError("Please enter discount percentage");
        flag = false
      }else{
        setDiscountPercentageError("");
        setDiscountAmountError("");
      }
    }else if(DiscountAmtPerType === '2'){
      if (DiscountAmount === "") {
        setDiscountAmountError("Please enter discount amount");
        flag = false
      }else{
        setDiscountAmountError("");
        setDiscountPercentageError("");
      }
    }

    if (NoOfDiscounts === '0' || NoOfDiscounts === "") {
      setNoOfDiscountsError("Please enter valid no of discount");
      flag = false
    }else {
      setNoOfDiscountsError("");
    }
    
    if(CodeType == 1){
      if (DiscountCode === "") {
        setDiscountCodeError("Please enter discount code");
        flag = false
      } else {
        setDiscountCodeError("");
      }
    }else{
      setDiscountCodeError("");
    }
    
    if (DiscountFromDate === '' || DiscountFromDate === 0) {
      setDiscountFromDateError("Please select discount from date");
      flag = false;
    } else { setDiscountFromDateError(""); }
    
    if (DiscountFromTime === '' || DiscountFromTime === 0) {
        setDiscountFromTimeError("Please select discount from time");
        flag = false;
    } else { setDiscountFromTimeError(""); }
    
    if (DiscountToDate === '' || DiscountToDate === 0) {
        setDiscountToDateError("Please select discount to date");
        flag = false;
    } else { setDiscountToDateError(""); }
    
    if (DiscountToTime === '' || DiscountToTime === 0) {
        setDiscountToTimeError("Please select discount to time");
        flag = false;
    }

    if (DiscountFromTime !== '' && DiscountToTime !== '') {

      const Discountstart = new Date(`${DiscountFromDate}T${DiscountFromTime}`);
      const Discountend = new Date(`${DiscountToDate}T${DiscountToTime}`);

      if (Discountstart >= Discountend) {
          setDiscountToTimeError("Please select to time greater than from time");
          flag = false;
      } else {
          setDiscountToTimeError('');
      }
    }
    

    var error_cat_flag = 0;
    if(ApplayTickets === 2){
      for (var j = 0; j < TicketsData.length; j++) {
        if (TicketsData[j]['checked'] === true) {
          error_cat_flag = 1;
        }
      }
      //console.log(error_cat_flag);
      if (error_cat_flag === 0) {
        setSelectedTicketError("Please select at least one race category");
        flag = false;
      } else {
        setSelectedTicketError("");
      }
    }else{
       setSelectedTicketError("");
    }
    
    //console.log(CodeType,HaveListCodes);
    if(CodeType === '2' && EventCommId === 0){
   
      const fileExtension = UploadCsvfile ? UploadCsvfile.name.split(".").at(-1) : '';
      const allowedFileTypes = ["csv"];

        if (UploadCsvfile === '') {
            setUploadCsvfileError("Please select csv file");
            setfiledivFlag(1)
            flag = false;
        } 
        else if (UploadCsvfile !== '') {
         // console.log('ss');
            if (!allowedFileTypes.includes(fileExtension)) {
                setUploadCsvfileError("File does not support. You must use csv");
                setfiledivFlag(1)
                ref.current.value = "";
                flag = false;
            } else if (UploadCsvfile.size > 5e6) {
                setUploadCsvfileError("Please upload a file smaller than 5 MB");
                setfiledivFlag(1)
                ref.current.value = "";
                flag = false;
            } else {
                setUploadCsvfileError("");
                setfiledivFlag(0)
            }
        }
        // console.log(flag);
    }else{
       setUploadCsvfileError("");
       setfiledivFlag(0)
    }
    
    if(UserEmailAddress !== ""){
      if (!isValid){
        //const emailList = UserEmailAddress.split(',').map(email => email.trim());
        // console.log('Valid emails:', emailList);
        setUserEmailAddressError("Invalid email format. Please enter valid email address");
        flag = false;
      }else{ setUserEmailAddressError(""); }
    }else{ setUserEmailAddressError(""); }
  
    return flag;
  };

 // console.log(UploadCsvfileError);
  const handleSubmit = (e) => {

    e.preventDefault();
    const flag = handleValidation();
    // console.log(flag);
    if (flag) {
      props.setLoader(true);
      //props.setTicketDivShowFlag(true);
      const jsonData = TicketsData ? JSON.stringify(TicketsData) : '';

      const formData = new FormData();
      formData.append("edit_coupon_id", EventCommId);
      formData.append("event_id", localStorage.getItem("EventId"));
      formData.append("user_id", localStorage.getItem("ID"));
      formData.append("discount_type", DiscounType);
      formData.append("discount_name", DiscounName);
      formData.append("discount_amt_per_type", DiscountAmtPerType);
      formData.append("discount_amount", DiscountAmount);
      formData.append("discount_percentage", DiscountPercentage);
      formData.append("code_type", CodeType);
      formData.append("no_of_discount", NoOfDiscounts);
      formData.append("discount_code", DiscountCode);
      formData.append("prefix_code", PrefixCode);
      formData.append("discount_from_date", DiscountFromDate);
      formData.append("discount_to_date", DiscountToDate);
      formData.append("discount_from_time", DiscountFromTime);
      formData.append("discount_to_time", DiscountToTime);
      formData.append("have_list_codes", HaveListCodes === true ? 1 : 0);
      formData.append("upload_csv", UploadCsvfile);
      formData.append("apply_ticket", ApplayTickets);
      formData.append("description", Description);
      formData.append("show_public", ShowPublic);
      formData.append("user_email_address", UserEmailAddress);
      formData.append("ticket_selected_data", jsonData);
      //console.log(formData);
      axios.post("add_edit_coupon", formData)
        .then((response) => {
          props.setLoader(false);
         //console.log(response);
            if (response.status === 200) {
              if(response.data.data === 1){
                setDiscounNameError(response.data.message);
                props.setAddCoupons(true);
              }else if(response.data.data === 2){
                setDiscountCodeError(response.data.message);
                props.setAddCoupons(true);
              } else{
                toast.success(response.data.message);
                props.setAddCoupons(false);
                props.setEventApiCall(true);
                props.setTicketDivShowFlag(false);
              }
              window.scrollTo(0, 0);
            }
        })
        .catch((error) => {
          props.setLoader(false);
          console.log(error);
        });
    }
  };
 // console.log();

  const handleTicketChange = (index, evnt) => {
   
    let TicketArray = [...TicketsData];
    TicketArray[index]['checked'] = evnt.target.checked;
    setTicketsData(TicketArray);
    //console.log(TicketsData);
  }

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month index starts from 0
  const day = String(currentDate.getDate()).padStart(2, '0');
  const CurrentDate1 = `${year}-${month}-${day}`;

  const handleInputChange = () => {
    inputRef.current.blur();
    inputRef1.current.blur();
  }   

  useEffect(() => {
    const handleKeyPress = (event) => {
        if (event.key === "Escape") {
            // Close the popup when the escape key is pressed
            setCsvUpoldedPopup(false);
        }
    };
    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);
    // Remove event listener when the component unmounts
    return () => {
        document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}
      
      {CsvUpoldedPopup === true ?
        <CouponCsvUploadDetails setCsvUpoldedPopup={setCsvUpoldedPopup}/>
      : null }

      {/* <div className="row"> */}
        {/* <div className="col-lg-12 col-md-6"> */}
        <div className="main checkout__mian">
         
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="checkout__content--step section__shipping--address ">
            <div className="mb-5">
              <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mb-4">
                <h2 className="section__header--title h3">Add Discount Coupon</h2>
                <div className="event-visibility hand_cursor">
                  
                    <div className={'visibility gap-1 ' + OneTimeUse} onClick={() => { setDiscounType(1); setOneTimeUse('active'); setMultipleTimeUse(''); }} > 
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM490-80H240q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v52q-18-6-37.5-9t-42.5-3v-40H240v400h212q8 24 16 41.5T490-80Zm230 40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm66-106 28-28-74-74v-112h-40v128l86 86ZM240-560v400-400Z" /></svg>
                      One time Use
                    </div>  
                  
                    <div className={'visibility gap-1 ' + MultipleTimeUse} onClick={() => { setDiscounType(2); setOneTimeUse(''); setMultipleTimeUse('active'); }} >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height={20}
                        viewBox="0 -960 960 960"
                        width={20}
                      >
                        <path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" /></svg>
                      Multiple Time Use
                    </div>

                </div>
              </div>
            </div>

            <div className="row ">
      
                <div className="col-sm-6 py-2">
                  <div className="form-floating mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={DiscounName}
                      onChange={(e) => {
                        setDiscounName(e.target.value);
                      }}
                      maxLength={100}
                    />
                    <label>Discount Name <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{DiscounNameError}</small>  
                </div>

                <div className="col-sm-6 py-2">
                  <div className="section__header checkout__section--header d-flex align-items-center gap-4 mb-4">
                    <p className="m-0">Show Public ?</p>
                    <div className="event-visibility hand_cursor">

                      <div className={'visibility gap-1 ' + YesStatus} onClick={() => { setShowPublic(1); setYesStatus('active'); setNoStatus(''); }} >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM490-80H240q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v52q-18-6-37.5-9t-42.5-3v-40H240v400h212q8 24 16 41.5T490-80Zm230 40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm66-106 28-28-74-74v-112h-40v128l86 86ZM240-560v400-400Z" /></svg>
                        Yes
                      </div>

                      <div className={'visibility gap-1 ' + NoStatus} onClick={() => { setShowPublic(0); setNoStatus('active'); setYesStatus(''); }} >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" /></svg>
                        No
                      </div>

                    </div>
                  </div>
                </div>
          
                <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                      <label className="select-lable">Discount Amount (Calculated per ticket) </label>
                      <select className="form-control" value={DiscountAmtPerType} onChange={(e) => { setDiscountAmtPerType(e.target.value); }} >
                        {/* <option value="0">-- Select Code Type --</option> */}
                        <option value="1">Percentage</option>
                        <option value="2">Amount</option>
                      </select>
                    </div>
                </div>

                  { DiscountAmtPerType === '1' ? 
                    <div className="col-sm-6 py-2">
                      <div className="form-floating mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={DiscountPercentage}
                          onChange={(e) => {
                            setDiscountPercentage(e.target.value.replace(/[^\d.]/g, ''));
                          }}
                        />
                        <label>Discount Percentage <span className="checkout__input--label__star">*</span></label>
                      </div>
                      <small className="text-danger">{DiscountPercentageError}</small>
                    </div> 
                  : null }
                
                { DiscountAmtPerType === '2' ?
                    <div className="col-sm-6 py-2">
                      <div className="form-floating mb-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={DiscountAmount}
                          onChange={(e) => {
                            setDiscountAmount(e.target.value.replace(/[^\d.]/g, ''));
                          }}
                        />
                        <label>Discount Amount <span className="checkout__input--label__star">*</span></label>
                      </div>
                      <small className="text-danger">{DiscountAmountError}</small>
                    </div>
                 : null } 

                <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                      <label className="select-lable">Code Type</label>
                      {EventCommId !== '' ?
                        <select className="form-control" value={CodeType} onChange={(e) => { setCodeType(e.target.value); }} disabled > 
                          {/* <option value="0">-- Select Code Type --</option> */}
                          <option value="1">Single Code</option>
                          <option value="2">List of Codes</option>
                        </select>
                      : 
                        <select className="form-control" value={CodeType} onChange={(e) => { setCodeType(e.target.value); }} > 
                          <option value="1">Single Code</option>
                          <option value="2">List of Codes</option>
                        </select>
                      }
                      
                    </div>
                </div>

                {CodeType && CodeType === '2' && EventCommId === '' ?
                <>
                  {/* <div className="col-sm-6 d-flex align-items-center justify-content-center py-2">
                     
                      <label for="file-upload" className="coupon-upload-csv px-3 py-4" type="file">
                      <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z"/></svg> Upload CSV {UploadedFileName && UploadedFileName !== '' ? '('+UploadedFileName+')' : ''}</label>
                      <input type="file" id="file-upload" className="form-control d-none" ref={ref} onChange={uplodCSVFile} />
                       
                  </div> */}

                  <div className="col-sm-6 d-flex align-items-center justify-content-center py-2 position-relative box-with-icon">
                    <label htmlFor="file-upload" className="coupon-upload-csv px-3 py-4" type="file">
                    <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" width="22"><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z"/></svg> Upload CSV <small className="m-0"> {UploadedFileName && UploadedFileName !== '' ? '('+UploadedFileName+')' : ''}</small></label>
                    <input type="file" id="file-upload" className="form-control d-none" ref={ref} onChange={uplodCSVFile} />
                    <div className="position-absolute input-info-icon tooltip">
                        
                        <svg className="" style={{right: "30px"}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={(e) => { setCsvUpoldedPopup(true); }}><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                        <span className="tooltiptext border-radius-10 p-3" >
                          <small className="text-white">Click on icon <svg style={{fill: "#fff"}} className="" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" ><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg> to view details</small>
                        </span>

                    </div>
                  </div>
           
                 
                  {filedivFlag === 1  ?
                    <>
                      <div className="col-sm-6 ">&nbsp;</div>
                      <div className="col-sm-6 "><small className="text-danger">{UploadCsvfileError}</small></div>
                    </>
                  : ''}

                   </>
                : 
                  <div className="col-sm-6">&nbsp;</div>
                }
                
                <div className="col-sm-6 py-2">
                  <div className="form-floating mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={NoOfDiscounts}
                      onChange={(e) => {
                        setNoOfDiscounts(e.target.value.replace(/\D/g, ''));
                      }}
                    />
                    <label>No. of Discounts <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{NoOfDiscountsError}</small>
                </div>
               
                {CodeType && CodeType === '2' ?
                  <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={PrefixCode}
                        onChange={(e) => {
                          setPrefixCode(e.target.value); 
                        }}
                      />
                      <label>Prefix (Optional)</label>
                    </div>
                 </div>

                :
                  
                <div className="col-sm-6 py-2">
                  <div className="form-floating mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={DiscountCode}
                      onChange={(e) => {
                        setDiscountCode(e.target.value); 
                      }}
                    />
                    <label>Discount Code <span className="checkout__input--label__star">*</span></label>
                  </div>
                  <small className="text-danger">{DiscountCodeError}</small>
                </div>
                }

                <div className="col-sm-6 py-2">
                     <div className="form-floating mb-0">
                        <input
                            className="form-control"
                            type="date"
                            name="event-date"
                            value={DiscountFromDate}
                            onChange={(e) => { setDiscountFromDate(e.target.value); setDiscountToDate(''); setDiscountToTime(''); }}
                            // min={DiscountFromDate == '' ? new Date(CurrentDate1).toISOString().split("T")[0]: new Date(CurrentDate1).toISOString().split('T')[0] }
                        />

                        <label className="select-label">Discount Availed From Date <span className="checkout__input--label__star">*</span></label>
                    </div>
                    <small className="text-danger">{DiscountFromDateError}</small>
                </div> 

                <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                        <input
                            className="form-control"
                            type="time"
                            name="event-date"
                            value={DiscountFromTime}
                            ref={inputRef}
                            onChange={(e) => { setDiscountFromTime(e.target.value); handleInputChange();  }}
                        />
                        <label className="select-label">Discount Availed From Time <span className="checkout__input--label__star">*</span></label>
                    </div>
                    <small className="text-danger">{DiscountFromTimeError}</small>
                </div>

                <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                        <input
                            className="form-control"
                            type="date"
                            name="event-date"
                            value={DiscountToDate}
                            onChange={(e) => { setDiscountToDate(e.target.value) }}
                            min={DiscountFromDate ? new Date(DiscountFromDate).toISOString().split("T")[0]: new Date().toISOString().split('T')[0]}
                        />
                        <label className="select-label">Discount Availed To Date <span className="checkout__input--label__star">*</span></label>
                    </div>
                    <small className="text-danger">{DiscountToDateError}</small>
                </div>

                <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                        <input
                            className="form-control"
                            type="time"
                            name="event-date"
                            value={DiscountToTime}
                            ref={inputRef1}
                            onChange={(e) => { setDiscountToTime(e.target.value); handleInputChange(); }}
                        />
                        <label className="select-label">Discount Availed To Time <span className="checkout__input--label__star">*</span></label>
                    </div>
                    <small className="text-danger">{DiscountToTimeError}</small>
                </div>

                <div className="col-sm-6 py-2">
                  <div className="form-floating mb-0">
                    <textarea
                        type="text"
                        className="form-control resp-textarea"
                       
                        value={Description} onChange={(e) => setDescription(e.target.value) }
                        maxLength={100}
                         
                    />
                    <label>Description</label>
                  </div>
                </div>
                
                {CodeType && CodeType === '1' ?
                  <div className="col-sm-6 py-2">
                    <div className="form-floating mb-0">
                      <textarea
                          type="text"
                          className="form-control resp-textarea"
                          
                          //value={UserEmailAddress} onChange={(e) => setUserEmailAddress(e.target.value) }
                          value={UserEmailAddress} onChange={handleEmailChange}

                          maxLength={100}
                          
                      />
                      <label>User Email Address</label>
                    </div>
                    <small className="text-danger">{UserEmailAddressError}</small>
                  </div>
                : '' }

                <div className="col-sm-12 py-2">
                    <label className="checkout__input--label mt-3" htmlFor="input1">
                        Apply Race Categories
                    </label>
                </div>

                <div className="section__header checkout__section--header d-flex align-items-center justify-content-between mt-4">

                {/* <h2 className="section__header--title h3"> Applay Tickets</h2> */}
                  <div className="ebi">
                    <div className="event-visibility hand_cursor">
                      <div className={'visibility gap-1 ' + AllTicktes} onClick={() => { setApplayTickets(1); setAllTicktes('active'); setSelectedTicktes(''); }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                        </svg>
                        All Race Categories
                      </div>

                      <div className={'visibility gap-1 ' + SelectedTicktes} onClick={() => { setApplayTickets(2); setAllTicktes(''); setSelectedTicktes('active'); }} > 
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height={20}
                          viewBox="0 -960 960 960"
                          width={20}
                        >
                          <path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" />
                        </svg>
                         Selected Race Categories
                      </div>

                    </div>
                  </div>
              </div>

                
                {TicketsData && TicketsData.length > 0 ?
                   TicketsData.map((item, index) => (
                    <>
                      {/* <div className="row" key={index+'11'}>
                        <div className="col-sm-6">

                          <div className="form-check form-switch d-flex align-items-left justify-content-left my-1">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              value={item.checked}
                              defaultChecked={item.checked}
                              onClick={(evnt) => { handleTicketChange(index, evnt); }}
                            />
                            <label className="form-check-label">{item.ticket_name}</label>
                          </div>  

                        </div>
                      </div> */}
                        {ApplayTickets === 2 ?
                        <div className="col-sm-6 my-3" key={index+'11'}>
                          <div className="plans">
                            <label className="plan basic-plan">
                              <input name="1"
                                type="checkbox"
                                className="btn-check"
                                value={item.checked}
                                defaultChecked={item.checked}
                                onClick={(evnt) => { handleTicketChange(index, evnt); }}
                              />
                              <div className="plan-content">
                                <div className="plan-details" for='btn-check-outlined-1'>
                                  <span className="h3 m-0"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm0-160q17 0 28.5-11.5T520-480q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440Zm0-160q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm320 440H160q-33 0-56.5-23.5T80-240v-160q33 0 56.5-23.5T160-480q0-33-23.5-56.5T80-560v-160q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v160q-33 0-56.5 23.5T800-480q0 33 23.5 56.5T880-400v160q0 33-23.5 56.5T800-160Zm0-80v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102h640ZM480-480Z"/></svg> {item.ticket_name}</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                        : null }
                    </>
                   ))
                : 
                  
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 py-5 my-2 pb-0" >
                    <div className="w-100 not-avilable-card p-3 pb-4 d-flex flex-column align-items-center">
                        <img className=""  src={`${noCoupons}`} alt="" style={{ width: "130px" }} />
                        <p class="section__header--title h4 mb-3">No race categories avilable</p>
                        <p class="mb-4">No race categories to apply discounts. Please create an available race categories first</p>
                    </div>
                  </div>
                } 
                 
                <small className="text-danger">{SelectedTicketError}</small>
                 
            </div>
          
          </div>
          <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
            <div
              className="continue__shipping--btn secondary__btn border-radius-5"
              onClick={(e) => { props.setAddCoupons(false); props.setTicketDivShowFlag(false) }}
              style={{cursor:'pointer'}} >
              Cancel
            </div>
              {TicketsData && TicketsData.length > 0 ?
                <input type="submit" name="command" value="Save" className="continue__shipping--btn primary__btn border-radius-5" />
              :
                <input type="button" name="command" value="Save" className="continue__shipping--btn disable__btn border-radius-5" />
              }
          </div>
        </form >
     
      {/* </div >
    </div>  */}
     

      </div>

    </>
  )
}

export default AddDiscountCoupons;

